/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'AnchorTarget',
        import: () => import('@stories/Widgets/AnchorTarget/AnchorTarget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApartmentFeatures',
        import: () => import('@stories/Widgets/ApartmentFeatures/ApartmentFeatures'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApartmentList',
        import: () => import('@stories/Widgets/ApartmentsList/ApartmentsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApartmentDetailBanner',
        import: () => import('@stories/Widgets/Banners/ApartmentDetailBanner/ApartmentDetailBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApartmentLandingBanner',
        import: () => import('@stories/Widgets/Banners/ApartmentLandingBanner/ApartmentLandingBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentInnerBanner',
        import: () => import('@stories/Widgets/Banners/ContentInnerBanner/ContentInnerBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/Banners/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroInnerBanner',
        import: () => import('@stories/Widgets/Banners/HeroInnerBanner/HeroInnerBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUsForm',
        import: () => import('@stories/Widgets/ContactUsForm/ContactUsForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FloorPlan',
        import: () => import('@stories/Widgets/FloorPlan/FloorPlan'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCard',
        import: () => import('@stories/Widgets/InformationCard/InformationCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCarousel',
        import: () => import('@stories/Widgets/InformationCarousel/InformationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LevelGuide',
        import: () => import('@stories/Widgets/LevelGuide/LevelGuide'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoStrip',
        import: () => import('@stories/Widgets/LogoStrip/LogoStrip'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RetailList',
        import: () => import('@stories/Widgets/RetailList/RetailList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteMap',
        import: () => import('@stories/Widgets/SiteMap/SiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SkipToContent',
        import: () => import('@stories/Widgets/SkipToContent/SkipToContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SpaceSpecification',
        import: () => import('@stories/Widgets/SpaceSpecification/SpaceSpecification'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StickyNavigation',
        import: () => import('@stories/Widgets/StickyNavigation/StickyNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'TabbedComponent',
        import: () => import('@stories/Widgets/TabbedComponent/TabbedComponent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TransportLinks',
        import: () => import('@stories/Widgets/TransportLinks/TransportLinks'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VirtualTour',
        import: () => import('@stories/Widgets/VirtualTour/VirtualTour'),
        config: {
            hydrate: 'in-view',
        },
    },
];
